import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private loginService: LoginService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  onSubmit(): void {
    const { username, password } = this.loginForm.value;
    if (username && password) {
      this.loginService.login(username, password).subscribe({
        next: (response: any) => {
          const authToken = response.authToken;
          const roleId = response.roleId; // Add roleId to response
          localStorage.setItem('authToken', authToken);
          localStorage.setItem('roleId', roleId); // Store roleId in local storage
          this.snackBar.open('Login successful', 'Close', { duration: 2000 });
          this.loginService.getUserDataByUserName(username).subscribe({
            next : (res:any) => {
              sessionStorage.setItem("userData",JSON.stringify(res.responseObject))
              this.router.navigate(['/home']); // Navigate to /home route
            }
          })
        },
        error: (error: HttpErrorResponse) => {
          console.error('Login error:', error);
          this.errorMessage = 'Login failed. Please try again.';
        }
      });
    } else {
      this.errorMessage = 'Invalid username or password';
    }
  }
}